import React, { ReactElement, ReactNode } from "react";
import "./index.scss";

interface ITypography {
  color?: string;
  children: ReactNode;
  variant: "display" | "title_large" | "title_regular" | "title_small" | "body_large" | "body_regular" | "body_small" | "link";
  bold?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export default function Typography({
  color = "#fff",
  children,
  variant,
  bold,
  className = "",
  style,
}: ITypography): ReactElement {
  const ariaLevels = {
    display: 1,
    title_large: 2,
    title_regular: 3,
    title_small: 4,
    body_large: 5,
    body_regular: 6,
    body_small: 7,
    link: 8,
  };
  return (
    <div
      className={`pq-typography pq-typography__${variant} ${className}`}
      role={"heading"}
      aria-level={ariaLevels[variant]}
      style={{
        ...style,
        color,
        fontWeight: bold || variant === "display" ? 700 : 400,
      }}
    >
      {children}
    </div>
  );
}
