import "./index.scss";

import Link from "../../components/Link";
import LogoDark from "../../icons/LogoDark";
import Mail from "../../icons/Mail";
import Phone from "../../icons/Phone";
import React from "react";
import Typography from "../../components/Typography";

export default function Footer(): React.ReactElement {
  return (
    <div className="pq-footer">
      <div className="pq-footer_content">
        <div className="pq-footer_top">
          <div className="pq-footer_logo">
            <LogoDark/>
          </div>
          <div className="pq-footer_tabs">
             <div className="pq-footer_link">
              <Link to={"/privacy"}>
                <Typography variant="body_regular" color="#0066FF">Privacy Policy</Typography>
              </Link>
            </div>
            <div className="pq-footer_link">
              <Link to={"/terms"}>
                <Typography variant="body_regular" color="#0066FF">Terms of Use</Typography>
              </Link>
            </div>
            </div>
        </div>
        <div className="pq-footer_bottom">
          <div className="pq-footer_link">
            <Mail/>
            <Link to="mailto:sales@pharmiq.co" external>
              <Typography variant="body_small" color="#0066FF">sales@pharmiq.co</Typography>
            </Link>
          </div>
          <div className="pq-footer_link">
            <Phone/>
            <Link to="tel:+12016083204" external>
              <Typography variant="body_small" color="#0066FF">+1 201-608-3204</Typography>
            </Link>
          </div>
        </div>
      </div>
      <div className="pq-footer_copyright">
        <Typography variant="body_small" color="#0B2143">
          {`©${new Date().getFullYear()} PharmIQ Technologies Inc.`}
        </Typography>
      </div>
    </div>
  );
}
