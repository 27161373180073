import React, { ReactElement } from "react";

interface IProps {
  size?: number;
  color?: string;
}

export default function Phone({
  color = "#0B2143",
  size = 16,
}: IProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M15.544 12.5688L11.9307 9.28349C11.7599 9.12825 11.5355 9.04546 11.3048 9.05259C11.0741 9.05972 10.8552 9.15622 10.6943 9.32171L8.56723 11.5092C8.05523 11.4115 7.02592 11.0906 5.96638 10.0337C4.90684 8.97327 4.58596 7.94129 4.49085 7.43285L6.67659 5.30489C6.84229 5.14411 6.93893 4.92518 6.94606 4.69441C6.9532 4.46365 6.87026 4.23916 6.71481 4.06846L3.43042 0.456072C3.2749 0.284836 3.05876 0.180969 2.8279 0.16653C2.59703 0.15209 2.36963 0.228216 2.19399 0.37874L0.265136 2.03294C0.111459 2.18717 0.0197344 2.39244 0.00736163 2.60982C-0.0059715 2.83204 -0.26019 8.09595 3.82152 12.1794C7.38236 15.7394 11.8427 15.9998 13.0712 15.9998C13.2507 15.9998 13.3609 15.9945 13.3903 15.9927C13.6076 15.9806 13.8128 15.8884 13.9663 15.7341L15.6196 13.8043C15.7707 13.6292 15.8474 13.402 15.8333 13.1712C15.8191 12.9403 15.7153 12.7241 15.544 12.5688Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="15.9998" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
