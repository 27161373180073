import {Link} from "gatsby";
import React, {ReactElement} from "react";

export default function LogoDark(): ReactElement {
  return (
    <Link to="/" about={"PharmIQ"}>
      <svg
        width="125"
        viewBox="0 0 173 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9848 3.50015H0V32.1067H6.17764V22.8216H12.9848C19.2462 22.8216 23.8687 18.8241 23.8687 13.16C23.8687 7.49585 19.2462 3.50015 12.9848 3.50015ZM12.3124 17.1999H6.17764V9.12181H12.3124C15.3798 9.12181 17.4807 10.7035 17.4807 13.16C17.4807 15.6164 15.3798 17.1999 12.3124 17.1999ZM39.2858 10.0778C36.5127 10.0778 34.1176 11.3273 32.7674 13.2024V3H26.6326V32.1067H32.7786V19.3722C32.7786 17.0394 34.7546 15.2086 37.3173 15.2086C39.7533 15.2086 41.4351 17.0394 41.4351 19.7044V32.1141H47.3594V18.407C47.3538 13.5346 43.9922 10.0778 39.2858 10.0778ZM61.3872 10.0778C58.3607 10.0778 55.3771 10.912 52.3097 12.4531L54.3696 16.4912C56.0923 15.6164 57.9827 14.9502 59.9997 14.9502C63.5718 14.9594 64.9984 16.9489 64.9984 19.2393V19.6564C63.2312 18.9649 61.3519 18.5978 59.4521 18.5731C54.4087 18.5731 50.4175 21.4891 50.4175 25.7358C50.4175 29.8588 53.9896 32.6069 58.6122 32.6069C60.9644 32.6069 63.4433 31.6841 64.9984 29.9419V32.1067H70.9227V19.2393C70.9246 13.6177 67.0601 10.0778 61.3872 10.0778ZM64.9984 25.3187C64.5775 27.3174 62.3519 28.4008 59.9978 28.4008C57.897 28.4008 56.3419 27.3174 56.3419 25.5697C56.3419 23.8219 58.1074 22.5263 60.3423 22.5263C61.9287 22.5221 63.5038 22.7893 64.9984 23.3162V25.3187ZM81.3877 14.5755V10.578H75.251V32.1067H81.3877V21.8213C81.3877 17.7831 84.2874 15.2012 88.8262 15.2012V10.0778C85.5036 10.0778 82.8143 11.7868 81.3877 14.5755ZM117.355 10.0778C114.118 10.0778 111.428 11.6189 110.294 13.9923C108.95 11.5783 106.383 10.0778 103.403 10.0778C100.714 10.0778 98.561 11.3697 97.5199 13.3999V10.578H91.3851V32.1067H97.5199V19.1157C97.5199 16.8253 99.2427 15.2012 101.722 15.2012C103.956 15.2012 105.547 16.9489 105.547 19.446V32.1473H111.428V19.1157C111.428 16.8253 113.194 15.2012 115.632 15.2012C117.9 15.2012 119.496 16.9489 119.496 19.446V32.1473H125.38V18.407C125.38 13.5346 121.892 10.0778 117.355 10.0778Z"
          fill="#06182E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M132.936 31.0594H140.087V0.441096H132.936V31.0594ZM167.822 27.5269C168.898 26.3524 169.73 24.9805 170.273 23.4888C170.855 21.8912 171.146 20.204 171.132 18.5057V12.9948C171.132 9.23962 169.915 6.13473 167.482 3.6801C165.048 1.22547 161.894 -0.00122947 158.019 9.24642e-07C154.112 9.24642e-07 150.946 1.2267 148.52 3.6801C146.094 6.1335 144.882 9.23839 144.883 12.9948V18.5057C144.883 22.2768 146.1 25.3854 148.533 27.8314C150.967 30.2775 154.143 31.5005 158.061 31.5005C158.932 31.5021 159.802 31.428 160.659 31.279C161.487 31.136 162.3 30.9211 163.09 30.6367L168.629 35.9982L173 32.4915L167.822 27.5269ZM163.982 18.5057C163.982 20.7204 163.466 22.5285 162.432 23.9299C161.399 25.3313 159.942 26.0326 158.061 26.0338C156.122 26.0338 154.632 25.3325 153.592 23.9299C152.551 22.5272 152.032 20.7192 152.033 18.5057V12.9542C152.033 10.753 152.546 8.95478 153.571 7.55952C154.597 6.16426 156.079 5.46663 158.017 5.46663C159.913 5.46663 161.38 6.16795 162.419 7.57059C163.459 8.97324 163.979 10.7678 163.98 12.9542L163.982 18.5057Z"
          fill="#35A6D5"
        />
      </svg>
    </Link>
  );
}
