import React, { ReactElement } from "react";

interface IProps {
  size?: number;
  color?: string;
}

export default function Mail({
  color = "#0B2143",
  size = 16,
}: IProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 5.032V12.1562C16 12.8826 15.746 13.5815 15.2899 14.1095C14.8338 14.6374 14.2102 14.9545 13.5472 14.9956L13.4 15H2.6C1.93589 15 1.29694 14.7221 0.814215 14.2233C0.331493 13.7244 0.0415989 13.0425 0.00400009 12.3173L0 12.1562V5.032L7.7216 9.456C7.8075 9.50521 7.90303 9.53092 8 9.53092C8.09697 9.53092 8.1925 9.50521 8.2784 9.456L16 5.032ZM2.6 1H13.4C14.0445 0.999915 14.666 1.26162 15.144 1.73436C15.6221 2.2071 15.9226 2.85717 15.9872 3.5585L8 8.13475L0.0128 3.5585C0.0748187 2.88502 0.35444 2.25783 0.8014 1.78966C1.24836 1.32148 1.83335 1.04304 2.4512 1.00438L2.6 1H13.4H2.6Z"
        fill={color}
      />
    </svg>
  );
}
