import React, { ReactElement } from "react";
import { Link as _link } from "gatsby";
import "./index.scss";

interface IProps {
  to: string;
  children: React.ReactNode;
  external?: boolean;
}

function Link({ to, children, external = false }: IProps): ReactElement {
  return (
    <div className="pq-link_container">
      {external ? (
        <a className="pq-link" href={to}>
          {children}
        </a>
      ) : (
        <_link className="pq-link" to={to}>
          {children}
        </_link>
      )}
    </div>
  );
}

export default Link;
